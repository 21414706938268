<!--贴心服务-公共监督-->
<template>
  <section>
    <!-- 工具条 -->
    <el-row>
      <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
        <el-form :inline="true" :model="filter" size="mini">
          <el-form-item>
            <el-select
              placeholder="派单状态"
              @change="getData"
              v-model="filter.status"
              clearable
              filterable>
              <el-option label="否" :value="0"></el-option>
              <el-option label="是" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-cascader
              placeholder="所属单位"
              @change="getData"
              v-model="filter.owner"
              :options="customerTree"
              :props="customerProps"
              clearable
              filterable/>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="filter.start_time"
              type="date"
              @change="getData"
              placeholder="提交日期"
              value-format="timestamp">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="handleDialogOpen(null,'新增',true)"
            >新增
            </el-button>
            <el-button
              type="primary"
              icon="el-icon-download"
              @click="openExportSizeInput"
              :loading="exportLoading"
            >导出
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <el-row>
      <el-col :span="24">
        <el-table
          :data="datalist.content"
          size="mini"
          border
          highlight-current-row
          v-loading="loading"
          :max-height="clientHeight"
          style="width: 100%"
          @selection-change="handleSelectChange"
        >
          <el-table-column type="selection" align="center" width="55"/>
          <el-table-column type="index" label="#" align="center" width="55"/>
          <el-table-column
            prop="locale_name"
            label="监测点"
            width="160"
            show-overflow-tooltip
            header-align="center"
          />
          <el-table-column
            prop="addr"
            label="地址"
            width="160"
            show-overflow-tooltip
            header-align="center"
          />
          <el-table-column
            prop="create_at"
            label="提交时间"
            align="center"
            width="140"
            show-overflow-tooltip
            header-align="center"
          />
          <el-table-column
            prop="contact"
            label="投诉人"
            align="center"
            width="110"
            show-overflow-tooltip
            header-align="center"
          />
          <el-table-column
            prop="mobile"
            label="投诉电话"
            align="center"
            width="140"
            show-overflow-tooltip
            header-align="center"
          />
          <el-table-column
            prop="locale_contact"
            label="餐饮单位联系人"
            align="center"
            width="110"
            show-overflow-tooltip
            header-align="center"
          />
          <el-table-column
            prop="locale_mobile"
            label="餐饮单位联系电话"
            align="center"
            width="140"
            show-overflow-tooltip
            header-align="center"
          />
          <el-table-column
            prop="remark"
            label="投诉内容"
            align="center"
            width="200"
            show-overflow-tooltip
            header-align="center"
          />
          <el-table-column
            prop="status_info"
            label="是否派单"
            align="center"
            width="140"
            show-overflow-tooltip
            header-align="center"
          />
          <el-table-column
            prop="management_status"
            label="现场监督情况"
            align="center"
            show-overflow-tooltip
            header-align="center"
          />
          <el-table-column
            label="操作"
            align="center"
            fixed="right"
            min-width="120"
            header-align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text" size="mini"
                @click="handleDialogOpen(scope.row, '查看', false)"
              >查看
              </el-button>
              <el-button
                type="text" size="mini"
                @click="handleDelete(scope.row)"
              >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- 工具条 -->
    <div class="toolbar">
      <el-button :disabled="ids.length ===0" class="batchButton" type="primary" size="small" @click="handleBatchClick">
        现场监督
      </el-button>
      <el-pagination
        small
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="filter.page"
        :page-sizes="filter.pageSizes"
        layout="total, sizes, prev, pager, next, jumper"
        :total="datalist.total"
        style="display: inline-block; margin-left: 15px"
      ></el-pagination>
    </div>
    <!--处理界面-->
    <el-dialog
      :title="dlg.title"
      :visible.sync="dlg.visible"
      @close="handleClose"
      width="550px"
      :disabled="dlg.isView"
    >
      <el-form
        :model="formData"
        :rules="formRule"
        ref="form"
        size="mini"
        label-width="130px"
        label-position="right"
        :disabled="dlg.isView"
      >
        <el-form-item prop="locale_name" label="监测点">
          <div v-if="dlg.title!=='新增'">{{ formData.locale_name }}</div>
          <el-select
            v-else
            filterable
            style="width: 100%"
            :filter-method="localeFilter"
            :disabled="dlg.title==='处理'"
            v-model="formData.locale_name"
            @change="handleLocaleSelect"
          >
            <el-option v-for="item in this.localeList"
                       :key="item.id"
                       :value="item.name"
                       :label="item.name"/>
          </el-select>
        </el-form-item>
        <el-form-item v-if="formData.addr" prop="addr" label="监测点地址">
          <div>{{ formData.addr }}</div>
        </el-form-item>
        <el-form-item v-if="formData.locale_contact" prop="contact" label="联系人">
          <div>{{ formData.locale_contact }}</div>
        </el-form-item>
        <el-form-item v-if="formData.locale_mobile" prop="mobile" label="联系电话">
          <div>{{ formData.locale_mobile }}</div>
        </el-form-item>
        <el-form-item v-if="formData.device_mns" prop="device_mns" label="设备编号">
          <div>{{ formData.device_mns }}</div>
        </el-form-item>
        <el-form-item v-if="dlg.title!=='新增'" prop="create_at" label="提交日期">
          <div>{{ formData.create_at }}</div>
        </el-form-item>
        <el-form-item prop="contact" label="投诉人">
          <div v-if="dlg.title!=='新增'">{{ formData.contact }}</div>
          <el-input
            v-else
            clearable
            placeholder="请填写"
            v-model.trim="formData.contact"/>
        </el-form-item>
        <el-form-item prop="mobile" label="投诉人联系电话">
          <div v-if="dlg.title!=='新增'">{{ formData.mobile }}</div>
          <el-input
            v-else
            clearable
            placeholder="请填写"
            v-model.trim="formData.mobile"/>
        </el-form-item>
        <el-form-item prop="remark" label="投诉内容">
          <div v-if="dlg.title !== '新增'">{{ formData.remark }}</div>
          <el-input
            v-else
            type="textarea"
            autosize
            v-model.trim="formData.remark"
          />
        </el-form-item>
        <el-form-item label="处理图片" v-if="dlg.title!=='新增'">
          <el-image
            class="view-img"
            v-for="(item, i) in formData.pics"
            :key="i"
            :src="item"
          >
            <div slot="error" class="image-slot">
              暂无图片
            </div>
          </el-image>
        </el-form-item>
        <el-form-item v-if="dlg.title==='新增'" label="处理图片" prop="upload">
          <el-upload
            ref="upload"
            :disabled="!dlg.isDeal"
            accept=".jpg, .jpeg, .png"
            list-type="picture-card"
            :action="upload()"
            :on-success="handleAvatarSuccess"
            :limit="4"
            :on-exceed="handleExceed"
            :on-remove="handleRemove"
            :on-preview="handlePreview"
          >
            <i class="el-icon-plus"/>
            <div slot="tip">上传照片（支持jpg、jpeg、png）</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="dlg.title !=='查看'">
        <el-button type="primary" size="mini" @click.native="handleSubmit()">确定</el-button>
        <el-button type="primary" size="mini" @click.native="dlg.visible = false">取消</el-button>
      </div>
    </el-dialog>
    <!--    图片预览dialog-->
    <el-dialog :visible.sync="dlg.showImg">
      <el-image
        :src="dlg.dialogImageUrl"
        fit="fill"
      >
        <div slot="error" class="image-slot">
          暂无图片
        </div>
      </el-image>
    </el-dialog>
  </section>
</template>

<script>
import {mapState} from 'vuex'
import {
  dateFormater,
  getUserInfo,
  export_json, ownerJoin, maintenanceStatusFormatter,
} from '@/util'

import {maintenanceTypeFormatter} from '@/util'
import conf from '@/config'

export default {
  name: 'PublicScrutiny',
  data() {
    return {
      conf,
      changeState: false,
      exportLoading: false,
      loading: false,
      datalist: {},
      userInfo: null,
      dlg: {
        dialogSubmitted: false,
        visible: false,
        isDeal: false,
        isView: false,
        showImg: false,
        dialogImageUrl: '',
        title: ''
      },
      filter: {
        page: 1,
        size: 20,
        pageSizes: [20, 30, 50]
      },
      ids: [],
      fileList: [],
      formData: {},
      formRule: {
        locale_name: [{required: true, message: '监测点不可为空'}],
        remark: [{required: true, message: '派单类型不可为空'}],
        upload: [{required: true, message: '上传照片不可为空'}]
      },
      localeList: []
    }
  },
  computed: {
    ...mapState({
      customerProps: state => Object.assign({}, state.props, {label: 'Org', checkStrictly: true})
    }),
    ...mapState(['props', 'clientHeight', 'customerTree', 'locale', 'user'])
  },
  created() {
    this.userInfo = getUserInfo().Info
    this.getData()
  },
  methods: {
    /* 图片模块 start */
    upload() {
      return conf.uploadApi + '/uploadToOss'
    },
    handleAvatarSuccess(res, file) {
      this.fileList.push(res.Data)
    },
    handleExceed(files, fileList) {
      this.$message.warning(`照片数量限制在4张`)
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    handlePreview(file) {
      this.dlg.showImg = true
      this.dlg.dialogImageUrl = file.response.Data
    },
    openExportSizeInput() {
      this.$prompt(`请输入导出的数据量（总量：${this.datalist.total} 条）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '只能填入数字'
      }).then(({value}) => {
        this.handleDownload(parseInt(value))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        })
      })
    },
    hasChange() {
      this.changeState = true
    },
    getData() {
      this.loading = true
      const payload = {
        StartAt: (this.filter.page - 1) * this.filter.size,
        Size: this.filter.size,
        status: this.filter.status,
      }
      if (this.filter.owner && this.filter.owner.length > 0) {
        payload.owner = ownerJoin(this.filter.owner, this.userInfo)
      }
      if (this.filter.start_time) {
        payload.start_time = this.filter.start_time / 1000
        payload.end_time = payload.start_time + 60 * 60 * 24
      }
      this.$get('admin/complaint', payload).then(res => {
        this.datalist.content = res.list
        this.datalist.total = res.list.length
        this.loading = false
      })
    },
    handleSelectChange(selection, row) {
      this.ids = []
      selection.forEach(item => {
        this.ids.push(item.id)
      })
    },
    handleRefresh() {
      this.filter.page = 1
      this.getData()
    },
    // 改变页容量
    handleSizeChange(size) {
      this.filter.size = size
      this.getData()
    },
    // 翻页
    handleCurrentChange(page) {
      this.filter.page = page
      this.getData()
    },
    handleDialogOpen(row, title, canEdit) {
      this.dlg.dialogSubmitted = false
      this.dlg.title = title
      canEdit ? this.dlg.isDeal = true : this.dlg.isView = true
      if (this.dlg.title === '新增') {
        this.formData = {}
        this.dlg.visible = true
      } else {
        this.$get('admin/complaint/info/' + row.id).then(res => {
          this.formData = res
          if (this.dlg.title === '查看') {
            this.formData.pics = this.formData.pics.split(',')
          }
          this.dlg.visible = true
        })
      }
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交吗？', '提示', {})
            .then(() => {
              this.formData.pics = this.fileList
              let param = new URLSearchParams()
              Object.entries(this.formData).forEach(([key, val]) => {
                param.append(key, val)
              })
              if (this.dlg.title === '新增') {
                this.$post('admin/complaint/add', param).then(res => {
                  this.$message({
                    type: "success",
                    message: '添加成功'
                  })
                  this.dlg.visible = false
                })
              }
            })
            .finally(() => {
              this.dlg.dialogSubmitted = true
            })
        }
      })
    },
    handleClose() {
      if (this.dlg.dialogSubmitted) {
        this.handleRefresh()
      }
      this.dlg.isView = false
      this.dlg.isDeal = false
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
    },
    handleDownload(exportSize) {
      this.exportLoading = true
      const payload = {
        StartAt: (this.filter.page - 1) * this.filter.size,
        Size: exportSize,
        status: this.filter.status,
        management_type: this.filter.management_type,
        is_download: 1
      }
      if (this.filter.owner && this.filter.owner.length > 0) {
        payload.owner = ownerJoin(this.filter.owner, this.userInfo)
      }
      if (this.filter.start_time) {
        payload.start_time = this.filter.start_time / 1000
        payload.end_time = payload.start_time + 60 * 60 * 24
      }

      this.$get('admin/complaint', payload).then(res => {
        let url = res.url
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.click()
        this.exportLoading = false
      })
    },
    handleDelete(row) {
      this.$post('admin/complaint/delete/' + row.id).then(res => {
        this.$message({
          type: 'success',
          message: '删除成功'
        })
        this.getData()
      })
    },
    localeFilter(val) {
      this.$get('admin/list_locale', {name: val}).then(res => {
        this.localeList = res
      })
    },
    handleLocaleSelect(val) {
      let item = this.localeList.find(item => item.name === val)
      this.formData.locale_id = item.id
      this.formData.addr = item.addr
      this.formData.locale_contact = item.contact
      this.formData.device_mns = item.device_mns
      this.formData.locale_mobile = item.mobile
    },
    handleBatchClick() {
      this.$confirm('确认针对该点位派发现场监督执法单吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        let param = new URLSearchParams()
        param.append('ids', this.ids.join(','))
        this.$post('admin/complaint/update', param).then(res => {
          this.$message({
            type: 'success',
            message: '派发成功'
          })
        }).catch(e => {
          this.$message({
            type: 'error',
            message: e.data
          })
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.batchButton {
  margin-left: 20px;
  margin-top: 10px;
}
</style>
